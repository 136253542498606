import { useEffect,useState } from "react"
import styles from "./detailsBody.module.css"
import { useSelector, useDispatch} from 'react-redux'
import { authActions } from '../../../store/authSlice'
import {useParams} from "react-router-dom"
import NamStatus from "./namStatus"
import General from "./general"
import LabMenu from "./labs/labMenu"
import axios from 'axios'
function DetailsBody(props){

    const dispatch=useDispatch()
    const {slug} = useParams()
    const [name,setName]=useState()
    const [organs,setOrgans]=useState()
    const [endpoints,setEndpoints]=useState()
    const [aop,setAop]=useState()
    const [regulations,setRegualtions]=useState()
    const [type,setType]=useState()
    const [testingMethod,setTestingMethod]=useState()
    const [keyEvent, setKeyEvent]=useState()
    const [reference,setReference]=useState()
    const [namDesc,setNamDesc]=useState()
    const [nanoUsed,setNanoUsed]=useState()
    const [nanoValidated,setNanoValidated]=useState()
    const [nonNanoValidated,setNonNanoValidated]=useState()
    const [nanoUsedRef,setNanoUsedRef]=useState()
    const [mie,setMie]=useState()
    const [references,setReferences]=useState([])
    const [actualData,setActualData]=useState()
    const authToken=useSelector((state)=>state.auth.access)
    const email=useSelector((state)=>state.auth.email)
    const [providers,setProviders]=useState([])

    function takeBack(){
        window.location='/explore'
    }
    const id_link=`${process.env.REACT_APP_DOMAIN}/api/nam_list_id?id=${slug}&test=test`

    async function dataDet(){
        const requestData=await axios.get(id_link, {
            headers:{
                'Content-Type':'application/json',
                'Authorization':"Bearer " + String(authToken)
            }})
    
        .then(function(response){
            setName(response.data[0].name)
            setAop(response.data[0].aop)
            setOrgans(response.data[0].organ)
            setEndpoints(response.data[0].endpoint)
            setRegualtions(response.data[0].regulations)
            setType(response.data[0].type)
            setTestingMethod(response.data[0].testing_method_strategy)
            setKeyEvent(response.data[0].key_event)
            setReference(response.data[0].reference)
            setNamDesc(response.data[0].name_desc)
            setNanoUsed(response.data[0].is_used_for_nano)
            setNanoValidated(response.data[0].is_nano_validated)
            setNanoUsedRef(response.data[0].nano_used_ref)
            setMie(response.data[0].mie)
            setActualData(response.data[0].actual_data)
            setReferences(response.data[0].reference.split(','))
           
        })
        .catch(function(error){
            if (error.toJSON().status==401){
                dispatch(authActions.setEmail(null))
                dispatch(authActions.setAdmin(null))
            dispatch(authActions.setAccess(null))
            dispatch(authActions.setReload(null))
            dispatch(authActions.setLoged(false))
            localStorage.removeItem('authTokens')
            }
        })
        .finally(function(){
            
        })
        return

    }
    async function getProviders(){
        let data=JSON.stringify({
            id:slug
        })
        const request = await axios.get(`${process.env.REACT_APP_DOMAIN}/api/nam_service_providers?id=${slug}`,{
            headers:{
                'Content-Type':'application/json'
        }})
        .then(function(response){
           
            setProviders(response.data.map((x,i)=>{
                x.key=i+1
                return x
            }))
        })
        .catch(function(error){

        })
        .finally(function(){

        })
    }

    useEffect(()=>{
        if (email){
            dataDet()
            getProviders()
        }
    },[])
    return (
        <div className={styles.block} >
             
            <div >
                 <div className={styles.name}>{name}</div>
             
                <div className={styles.rows}>
                     <div className={styles.namStatus}>
                         <NamStatus  used={nanoUsed} usedRef={nanoUsedRef} nanoVal={nanoValidated} nonNanoVal={nonNanoValidated} actualData={actualData}/>
                     </div>
                     <div className={styles.general}>
                         <General keyEvent={keyEvent} test={testingMethod} aop={aop} regulations={regulations} organs={organs} endpoint={endpoints}
                         desc={namDesc} reference={reference} mie={mie} type={type}/>
                     </div>
        
                </div>
            </div>

            {namDesc!="-"&& namDesc!=undefined &&
            <div>
                 <div className={styles.desc}>NAM Description</div>
                 <div className={styles.descText}>{namDesc!="-"&&namDesc}</div>
            </div>
            }
           

             {references!="-" && references!=undefined &&
             <div className={styles.linkRow}>
                 {reference !="-" && reference !=undefined &&<div className={styles.subTitle}>{"Link(s)"}:</div>}
                 {reference !="-" && reference !=undefined && <div className={styles.linker}>{reference.split(',').map(x=>x!="-" &&<div className={styles.reference}>{<a target="_blank" href={x}>{x}</a>}</div>)}</div>} 
             </div>
             }
             <LabMenu providers={providers}/>
             <div className={styles.backLink}>
             <button className={styles.backButton} onClick={takeBack}><div className={styles.back}>Back</div> </button>
             </div>
             <div className={styles.space}></div>
        </div>
    ) 
}
export default DetailsBody