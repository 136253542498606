import styles from './FormElement.module.css'
import { LiaQuestionSolid } from "react-icons/lia";
import { useState } from 'react';
function FormElement(props){
    const [modal,setModal]=useState(false)
    console.log(modal)
    if (modal==true){
        console.log(props.desc)
    }
    return(
        <div className={styles.block}>
            <div className={styles.label}>{props.label}
                <button className={`${styles.questionButt} ${styles.labelQuestionButt} `} onMouseEnter={(e)=>{setModal(true)}} onMouseLeave={(e)=>setModal(false)}><LiaQuestionSolid className={`${styles.questionIcon} ${styles.labelQuestionIcon}`}/></button>
                {modal &&<div className={`${styles.modal} ${styles.labelModal}` }><div className={styles.modalText}>{props.desc}</div></div>}
            </div>
            <input value={props.value} onChange={(e)=>props.setter(e.target.value)} className={styles.input}></input>
            <button className={styles.questionButt} onMouseEnter={(e)=>{setModal(true)}} onMouseLeave={(e)=>setModal(false)}><LiaQuestionSolid className={styles.questionIcon}/></button>
            {modal &&<div className={styles.modal}><div className={styles.modalText}>{props.desc}</div></div>}
            
        </div>
    )
}

export default FormElement